import React, { useEffect, useRef, useState } from 'react'
import { Link, useLocation, createSearchParams, useNavigate } from 'react-router-dom'
import MetaTags from 'react-meta-tags'
import { Card, CardBody, Col, Container, Row, Button, Form, FormGroup, FormFeedback, Label, Input, FormText } from 'reactstrap'
import toastr from 'toastr'
import AsyncSelect from 'react-select/async'
import Breadcrumbs from '../../components/Common/Breadcrumb'
import { Controller, useForm } from 'react-hook-form'
import { APIClient } from '../../lib/apiHelper'

const UserCompanyAddForm = () => {
  const navigate = useNavigate()
  const {
    register,
    control,
    getValues,
    trigger,
    handleSubmit,
    formState: { errors, dirtyFields },
  } = useForm()

  const [state, setState] = useState({
    companyList: [] as any[],
    roleList: [] as any[],
    validEmail: undefined,
  })

  useEffect(() => {
    loadRoleList()
  }, [])

  const loadRoleList = async () => {
    try {
      const companyId = 1 //FIXME: role 를 회사와 무관하게 만들어야 함!!      
      const { data: result } = await APIClient.get(`/company/role?companyId=${companyId}`)

      if (result.result) {
        const roleList = result.data.roleList

        setState((prev: any) => {
          return {
            ...prev,
            roleList,
          }
        })
      }
    } catch (error: any) {
      toastr.error(error.message)
    }
  }

  const onSubmit = async (data: any) => {
    console.log('onSubmit', data)

    const params = {
      user: {
        email: data.email,
        password: data.password,
        name: data.name,
        phone: data.phone,
        mailing: true,
        currentCompanyId: data.company.id,
      },
      companyId: data.company.id,
      roleId: data.roleId,
      joinDate: data.joinDate,
      companyNumber: data.companyNumber,
      state: data.state,
    }

    console.log('params:', params)

    try {
      const { data: result } = await APIClient.post(`/userCompany`, params)

      if (result.result) {
        toastr.success('사용자 등록 되었습니다')  

        navigate('/user')
      }
    } catch (error: any) {
      toastr.error(error.message)
    }
  }

  const onError = (d: any) => {
    console.error('error', d)
  }

  const validateEmail = async (v: string) => {
    console.log('v:', v)
    if (!v) {
      return false
    }

    try {
      if (v) {
        const { data: result } = await APIClient.get(`/userCompany/searchId?email=${v}`)

        console.log('result:', result)

        if (result.result) {
          return result.data.count === 0 || '사용 불가능한 이메일입니다'
        }
      }
    } catch (error: any) {
      toastr.error(error.message)
    }

    return false
  }

  const formOption = {
    email: register('email', {
      required: true,
      pattern: { value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/, message: '유효한 이메일을 입력해주세요' },
      validate: validateEmail,
    }),
    name: register('name', { required: true }),
    phone: register('phone', { required: true }),
    companyNumber: register('companyNumber', { required: false }),
    joinDate: register('joinDate', { required: false }),
    password: register('password', { required: true }),
    company: register('company', { required: true }),
    roleId: register('roleId', { required: true }),
    state: register('state', { required: true }),
  }

  const checkEmail = async () => {
    console.log('checkEmail')

    try {
      const values = getValues()
      const email = values.email

      if (email) {
        const { data: result } = await APIClient.get(`/userCompany/searchId?email=${email}`)

        console.log('result:', result)

        if (result.result) {
          //make valid
          setState((prev: any) => {
            return {
              ...prev,
              validEmail: result.data.count === 0,
            }
          })
        }
      }
    } catch (error: any) {
      toastr.error(error.message)
    }
  }

  const loadOptions = (inputValue: string, callback: (options: any[]) => void) => {
    APIClient.get(`/company/simpleSearch?q=${inputValue}`)
      .then((res) => {
        console.log('loadOptions:', res)

        const companyList = res.data.data.rows
        const optionList = companyList.map((company: any) => {
          return {
            id: company.id,
            value: company.id,
            label: company.name,
          }
        })

        callback(optionList)
      })
      .catch((error) => {
        callback([])
      })
  }

  const handleInputChange = (newValue: string) => {
    const inputValue = newValue.replace(/\W/g, '')
  }

  return (
    <>
      <div className="page-content">
        <MetaTags>
          <title>사용자 등록</title>
        </MetaTags>

        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="회원관리" breadcrumbItem="사용자 등록" />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <Form onSubmit={handleSubmit(onSubmit, onError)}>
                    <Row>
                      <Col xs={5}>
                        <FormGroup row>
                          <Label sm={4}>*아이디(이메일)</Label>
                          <Col sm={8}>
                            <Input
                              name="email"
                              onChange={formOption.email.onChange}
                              onBlur={formOption.email.onBlur}
                              innerRef={formOption.email.ref}
                              invalid={!!errors.email}
                            />
                            {errors.email && <FormText color="danger"> {errors.email.message} </FormText>}
                          </Col>
                          {/* <Col sm={2}>
                            <Button outline onClick={checkEmail}>
                              조회
                            </Button>
                          </Col> */}
                        </FormGroup>
                      </Col>
                      <Col xs={1} />
                      <Col xs={5}>
                        <FormGroup row>
                          <Label sm={4}>*비밀번호</Label>
                          <Col sm={8}>
                            <Input
                              name="password"
                              type="password"
                              onChange={formOption.password.onChange}
                              onBlur={formOption.password.onBlur}
                              innerRef={formOption.password.ref}
                              invalid={!!errors.password}
                            />
                          </Col>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={5}>
                        <FormGroup row>
                          <Label sm={4}>*이름</Label>
                          <Col sm={8}>
                            <Input
                              name="name"
                              onChange={formOption.name.onChange}
                              onBlur={formOption.name.onBlur}
                              innerRef={formOption.name.ref}
                              invalid={!!errors.name}
                            />
                          </Col>
                        </FormGroup>
                      </Col>
                      <Col xs={1} />
                      <Col xs={5}>
                        <FormGroup row>
                          <Label sm={4}>*회사명</Label>
                          <Col sm={8}>
                            <Controller
                              name="company"
                              control={control}
                              render={({ field }) => (
                                <AsyncSelect
                                  {...field}
                                  cacheOptions
                                  styles={{
                                    control: (styles) => ({
                                      ...styles,
                                      borderColor: errors.company ? 'red' : styles.borderColor,
                                      '&:hover': {
                                        borderColor: errors.company ? 'red' : styles.borderColor,
                                      },
                                    }),
                                  }}
                                  loadOptions={loadOptions}
                                  defaultOptions
                                  onInputChange={handleInputChange}
                                />
                              )}
                            />
                          </Col>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={5}>
                        <FormGroup row>
                          <Label sm={4}>*휴대폰전화</Label>
                          <Col sm={8}>
                            <Input
                              name="phone"
                              onChange={formOption.phone.onChange}
                              onBlur={formOption.phone.onBlur}
                              innerRef={formOption.phone.ref}
                              invalid={!!errors.phone}
                            />
                          </Col>
                        </FormGroup>
                      </Col>
                      <Col xs={1} />
                      <Col xs={5}>
                        <FormGroup row tag="fieldset">
                          <legend className="col-form-label col-sm-4">*권한</legend>
                          <Col sm={8} className="pt-2">
                            {state.roleList.map((role) => (
                              <FormGroup check inline key={`role_key_${role.id}`}>
                                <Input
                                  type="radio"
                                  name="roleId"
                                  value={role.id}
                                  onChange={formOption.roleId.onChange}
                                  onBlur={formOption.roleId.onBlur}
                                  innerRef={formOption.roleId.ref}
                                  invalid={!!errors.roleId}
                                />
                                <Label>{role.name}</Label>
                              </FormGroup>
                            ))}
                          </Col>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={5}>
                        <FormGroup row>
                          <Label sm={4}>사번</Label>
                          <Col sm={8}>
                            <Input
                              name="companyNumber"
                              onChange={formOption.companyNumber.onChange}
                              onBlur={formOption.companyNumber.onBlur}
                              innerRef={formOption.companyNumber.ref}
                              invalid={!!errors.companyNumber}
                            />
                          </Col>
                        </FormGroup>
                      </Col>
                      <Col xs={1} />
                      <Col xs={5}>
                        <FormGroup row tag="fieldset">
                          <legend className="col-form-label col-sm-4">*재직현황</legend>
                          <Col sm={8} className="pt-2">
                            <FormGroup check inline>
                              <Input
                                type="radio"
                                name="state"
                                value="SUCCESS"
                                onChange={formOption.state.onChange}
                                onBlur={formOption.state.onBlur}
                                innerRef={formOption.state.ref}
                                invalid={!!errors.state}
                              />
                              <Label>재직</Label>
                            </FormGroup>
                            <FormGroup check inline>
                              <Input
                                type="radio"
                                name="state"
                                value="LEAVE"
                                onChange={formOption.state.onChange}
                                onBlur={formOption.state.onBlur}
                                innerRef={formOption.state.ref}
                                invalid={!!errors.state}
                              />
                              <Label>퇴사</Label>
                            </FormGroup>
                            <FormGroup check inline>
                              <Input
                                type="radio"
                                name="state"
                                value="TEMPORAL_LEAVE"
                                onChange={formOption.state.onChange}
                                onBlur={formOption.state.onBlur}
                                innerRef={formOption.state.ref}
                                invalid={!!errors.state}
                              />
                              <Label>휴직</Label>
                            </FormGroup>
                          </Col>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={5}>
                        <FormGroup row>
                          <Label sm={4}>입사일</Label>
                          <Col sm={8}>
                            <Input
                              name="joinDate"
                              type="date"
                              onChange={formOption.joinDate.onChange}
                              onBlur={formOption.joinDate.onBlur}
                              innerRef={formOption.joinDate.ref}
                              invalid={!!errors.joinDate}
                            />
                          </Col>
                        </FormGroup>
                      </Col>
                      <Col xs={2}></Col>
                      <Col xs={5}></Col>
                    </Row>

                    <Row>
                      <Col>
                        <div className="d-flex gap-2 justify-content-center">
                          <Button
                            color="secondary"
                            onClick={() => {
                              navigate(-1)
                            }}
                          >
                            최소
                          </Button>
                          <Button type="submit" color="primary">
                            저장
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default UserCompanyAddForm
