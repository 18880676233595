import axios from "axios"
import env from "./env"
import { getItem, removeItem, setItem } from "./localstorage"
import jwtDecode from "jwt-decode"

const axiosApi = axios.create({
  baseURL: env.API_URL,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
}
})

// intercepting to capture errors
axiosApi.interceptors.request.use(
  request => {
    request.headers!["client-id"] = env.CLIENT_ID

    const accessToken = getItem("access-token")
    const refreshToken = getItem("refresh-token")

    if (accessToken) {
      let tokenData = jwtDecode(accessToken) as any

      if (tokenData) {
        request.headers!["authorization"] = "Bearer " + accessToken
        request.headers!["refresh-token"] = "Bearer " + refreshToken
        request.headers!["user-id"] = tokenData.id
      }
    }

    return request
  },
  error => {
    console.error('axios request interceptor error:', error)

    return Promise.reject(error)
  }
)

axiosApi.interceptors.response.use(
  response => {
    // Any status code that lie within the range of 2xx cause this function to trigger

    if (response.data.result) {
      if (response.headers["access-token"]) {
        setItem("access-token", response.headers["access-token"])
      }
      if (response.headers["refresh-token"]) {
        setItem("refresh-token", response.headers["refresh-token"])
      }

      return response
    } else {
      console.error('axios response interceptor:', response)

      console.log('response?.data:', response?.data)

      let code = response.data.data?.code || 500
      let message = response.data.data?.message || 'Internal Server Error'

      console.log({code, message})
      
      return Promise.reject({code, message})
    }
  },
  error => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger

    console.error('axios response interceptor error:', error)

    let code = error.response?.data?.code || 500
    const message = error.response?.data?.message || error.message || 'Internal Server Error';

    if (code === 401) {
      removeItem('access-token')
      removeItem('refresh-token')
    }

    return Promise.reject({code, message});
  }
)

class APIClient {
  /**
   * Fetches data from given url
   */
  static get = (url: string) => axiosApi.get(url)

  /**
   * post given data to url
   */
  static post = (url: string, data: object | null | undefined) => axiosApi.post(url, data)

  /**
   * Updates data
   */
  static put = (url: string, data: object | null | undefined) => axiosApi.put(url, data)

  /**
   * Delete
   */
  static remove = (url: string, data?: object | null | undefined) => axiosApi.delete(url, {data: data})
}

export { APIClient }


  // /**
  //  * Fetches data from given url
  //  */
  //  get = (url, params) => {
  //   try {
  //     return axiosApi.get(url, params)
  //   } catch (e) {
  //     console.log(e)
  //   }
  // }

  // /**
  //  * post given data to url
  //  */
  // post = (url, data) => {
  //   try {
  //     return axiosApi.post(url, data)
  //   } catch (e) {
  //     console.log(e)
  //   }
  // }

  // /**
  //  * Updates data
  //  */
  // put = (url, data) => {
  //   try {
  //     return axiosApi.put(url, data)
  //   } catch (e) {
  //     console.log(e)
  //   }
  // }

  // /**
  //  * Delete
  //  */
  // delete = url => {
  //   try {
  //     return axiosApi.delete(url)
  //   } catch (e) {
  //     console.log(e)
  //   }
  // }
