import React, { useEffect, useRef, useState } from 'react'
import { Link, useLocation, createSearchParams, useNavigate } from 'react-router-dom'
import {
  Card,
  CardBody,
  Col,
  Row,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  FormFeedback,
} from 'reactstrap'
import { useForm, Controller } from 'react-hook-form'
import toastr from "toastr";
import Select from 'react-select'
import { APIClient } from '../../lib/apiHelper'

interface AdminEditFormProps {
  selectedId: number | null
  adminRoleList: any[]
  onClose: (reload: boolean) => void
}

const AdminUserEditForm = ({ selectedId, onClose, adminRoleList }: AdminEditFormProps) => {
  const navigate = useNavigate()
  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm()

  const [state, setState] = useState({
    adminUser: null as any,
  })

  const [changePassword, setChangePassword] = useState(false)

  const formOption = {
    email: register('email', { required: true }),
    name: register('name', { required: true }),
    phone: register('phone', { required: true }),
    password: register('password', { required: false }),

    companyName: register('companyName', { required: true }),
    departName: register('departName', { required: false }),
    rankName: register('rankName', { required: false }),
    adminRoleId: register('adminRoleId', { required: true }),
  }

  useEffect(() => {
    if (selectedId) {
      loadDetail(selectedId)
    }
  }, [selectedId])

  const loadDetail = async (id: number) => {
    try {
      const { data: result } = await APIClient.get(`/adminUser/${id}`)

      if (result.result) {
        const adminUser = result.data.adminUser
  
        setState({
          ...state,
          adminUser,
        })
  
        //set form data
        const values = {
          email: adminUser.email,
          name: adminUser.name,
          phone: adminUser.phone,
  
          companyName: adminUser.companyName,
          departName: adminUser.departName,
          rankName: adminUser.rankName,
          adminRoleId: adminUser.adminRoleId,
        }
  
        console.log('values:', values)
  
        reset(values)
      }
    } catch (error: any) {
      toastr.error(error.message)
    }
  }

  const onSubmit = async (data: any) => {
    console.log('onSubmit', data)

    const params = {
      name: data.name,
      password: data.password,
      phone: data.phone,

      companyName: data.companyName,
      departName: data.departName,
      rankName: data.rankName,
      adminRoleId: data.adminRoleId,
    }

    console.log('params', params)

    try {
      const { data: result } = await APIClient.put(`/adminUser/${state.adminUser.id}`, params)

      if (result.result) {
        toastr.success('운영자 정보 수정 되었습니다')
  
        onClose(true)
      }
    } catch (error: any) {
      toastr.error(error.message)
    }
  }

  const onError = (d: any) => {
    console.error('error', d)
  }

  return (
    <>
      {state.adminUser && (
        <>
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <Form onSubmit={handleSubmit(onSubmit, onError)}>
                    <FormGroup>
                      <Label>*아이디(이메일)</Label>
                      <Row>
                        <Col>
                          <Input
                            name="email"
                            type="email"
                            readOnly={true}
                            onChange={formOption.email.onChange}
                            onBlur={formOption.email.onBlur}
                            innerRef={formOption.email.ref}
                            invalid={!!errors.email}
                          />
                        </Col>
                      </Row>
                    </FormGroup>

                    <FormGroup row>
                      <Label>*비밀번호</Label>
                      <Col sm={9}>
                        <Input
                          name="password"
                          type="password"
                          readOnly={!changePassword}
                          onChange={formOption.password.onChange}
                          onBlur={formOption.password.onBlur}
                          innerRef={formOption.password.ref}
                          invalid={!!errors.password}
                        />
                      </Col>
                      <Col sm={3}>
                        <Button type="button" outline onClick={() => {setChangePassword(true)}}>초기화</Button>
                      </Col>
                    </FormGroup>
                    <FormGroup>
                      <Label>*이름</Label>
                      <Col>
                        <Input
                          name="name"
                          onChange={formOption.name.onChange}
                          onBlur={formOption.name.onBlur}
                          innerRef={formOption.name.ref}
                          invalid={!!errors.name}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup>
                      <Label>*회사명</Label>
                      <Col>
                        <Input
                          name="companyName"
                          onChange={formOption.companyName.onChange}
                          onBlur={formOption.companyName.onBlur}
                          innerRef={formOption.companyName.ref}
                          invalid={!!errors.companyName}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup>
                      <Label>*연락처</Label>
                      <Col>
                        <Input
                          name="phone"
                          onChange={formOption.phone.onChange}
                          onBlur={formOption.phone.onBlur}
                          innerRef={formOption.phone.ref}
                          invalid={!!errors.phone}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup>
                      <Label>부서</Label>
                      <Col>
                        <Input
                          name="departName"
                          onChange={formOption.departName.onChange}
                          onBlur={formOption.departName.onBlur}
                          innerRef={formOption.departName.ref}
                          invalid={!!errors.departName}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup>
                      <Label>직급</Label>
                      <Col>
                        <Input
                          name="rankName"
                          onChange={formOption.rankName.onChange}
                          onBlur={formOption.rankName.onBlur}
                          innerRef={formOption.rankName.ref}
                          invalid={!!errors.rankName}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup>
                      <Label>*권한</Label>
                      <Col>
                        <Controller
                          name="adminRoleId"
                          control={control}
                          render={({ field }) => (
                            <Select
                              {...field}
                              classNamePrefix="select2-selection"
                              value={adminRoleList.find((c) => c.value === field.value)}
                              onChange={(val) => field.onChange(val?.value)}
                              options={adminRoleList}
                            />
                          )}
                        />
                      </Col>
                    </FormGroup>

                    <Row>
                      <Col>
                        <div className="d-flex gap-2 justify-content-center">
                          <Button
                            color="secondary"
                            onClick={() => {
                              onClose(true)
                            }}
                          >
                            최소
                          </Button>
                          <Button type="submit" color="primary">
                            저장
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </>
      )}
    </>
  )
}

export default AdminUserEditForm
