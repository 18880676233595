import React, { useEffect, useRef, useState } from 'react'
import { Link, useLocation, createSearchParams, useNavigate } from 'react-router-dom'
import { Card, CardBody, Col, Media, Row, Button, Form, FormGroup, Label, Input, FormText, FormFeedback } from 'reactstrap'
import toastr from 'toastr'
import { useForm } from 'react-hook-form'
import { APIClient } from '../../lib/apiHelper'
import moment from 'moment'

interface UserCompanyEditFormProps {
  selectedId: number | null
  onClose: (reload: boolean) => void
}

const UserCompanyEditForm = ({ selectedId, onClose }: UserCompanyEditFormProps) => {
  const navigate = useNavigate()
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm()

  const [state, setState] = useState({
    userCompany: null as any,
    roleList: [] as any[],
  })

  const [changePassword, setChangePassword] = useState(false)

  const formOption = {
    phone: register('phone', { required: true }),
    companyNumber: register('companyNumber', { required: false }),
    joinDate: register('joinDate', { required: false }),
    password: register('password', { required: false }),
    roleId: register('roleId', { required: true }),
    state: register('state', { required: true }),
  }

  useEffect(() => {
    if (selectedId) {
      loadDetail(selectedId)
    }
  }, [selectedId])

  const loadRoleList = async () => {
    try {
      const companyId = 1 //FIXME: role 를 회사와 무관하게 만들어야 함!!
      const { data: result } = await APIClient.get(`/company/role?companyId=${companyId}`)

      if (result.result) {
        const roleList = result.data.roleList

        console.log('roleList:', roleList)

        setState((prev: any) => {
          return {
            ...prev,
            roleList,
          }
        })
      }
    } catch (error: any) {
      toastr.error(error.message)
    }
  }

  const loadDetail = async (userCompanyId: number) => {
    await loadRoleList()

    try {
      const { data: result } = await APIClient.get(`/userCompany/${userCompanyId}`)

      if (result.result) {
        const userCompany = result.data.userCompany

        setState((prev: any) => {
          return {
            ...prev,
            userCompany: userCompany,
          }
        })

        //set form data
        const values = {
          phone: userCompany.user.phone,
          roleId: userCompany.roleId + '',
          joinDate: userCompany.joinDate ? moment(userCompany.joinDate).format('YYYY-MM-DD') : '',
          companyNumber: userCompany.companyNumber,
          state: userCompany.state,
        }

        console.log('values:', values)

        reset(values)
      }
    } catch (error: any) {
      toastr.error(error.message)
    }
  }

  const onSubmit = async (data: any) => {
    console.log('onSubmit', data)

    const params = {
      user: {
        email: data.email,
        password: data.password,
        phone: data.phone,
      },
      roleId: data.roleId,
      joinDate: data.joinDate,
      companyNumber: data.companyNumber,
      state: data.state,
    }

    try {
      const { data: result } = await APIClient.put(`/userCompany/${state.userCompany.id}`, params)

      if (result.result) {
        toastr.success('사용자 정보 수정 되었습니다')

        onClose(true)
      }
    } catch (error: any) {
      toastr.error(error.message)
    }
  }

  const onError = (d: any) => {
    console.error('error', d)
  }

  return (
    <>
      {state.userCompany && (
        <>
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <Row>
                    <Col>
                      <Media className="mb-5">
                        <div className="me-3">
                          {/* <img src={avatar} alt="" className="avatar-md rounded-circle img-thumbnail" /> */}
                          {!state.userCompany?.user?.profile ? (
                            <div className="avatar-md">
                              <span className="avatar-title rounded-circle">{state.userCompany.user.name.charAt(0)}</span>
                            </div>
                          ) : (
                            <div>
                              <img className="rounded-circle avatar-md" src={state.userCompany.user.profile} alt={state.userCompany.user.name} />
                            </div>
                          )}
                        </div>
                        <Media body className="align-self-center">
                          <div className="text-muted">
                            <h5>{state.userCompany.user.name}</h5>
                            <p className="mb-1">{state.userCompany.company.name}</p>
                          </div>
                        </Media>
                      </Media>
                    </Col>
                  </Row>
                  <Form onSubmit={handleSubmit(onSubmit, onError)}>
                    <FormGroup>
                      <Label>*아이디(이메일)</Label>
                      <Row>
                        <Col>
                          <Input name="email" type="email" readOnly={true} value={state.userCompany.user.email} />
                        </Col>
                      </Row>
                    </FormGroup>

                    <FormGroup row>
                      <Label>*비밀번호</Label>
                      <Col sm={9}>
                        <Input
                          name="password"
                          type="password"
                          readOnly={!changePassword}
                          onChange={formOption.password.onChange}
                          onBlur={formOption.password.onBlur}
                          innerRef={formOption.password.ref}
                          invalid={!!errors.password}
                        />
                      </Col>
                      <Col sm={3}>
                        <Button outline onClick={() => {setChangePassword(true)}}>초기화</Button>
                      </Col>
                    </FormGroup>

                    <FormGroup>
                      <Label>*휴대폰전화</Label>
                      <Col>
                        <Input
                          name="phone"
                          onChange={formOption.phone.onChange}
                          onBlur={formOption.phone.onBlur}
                          innerRef={formOption.phone.ref}
                          invalid={!!errors.phone}
                        />
                      </Col>
                    </FormGroup>

                    <FormGroup>
                      <Label>사번</Label>
                      <Col>
                        <Input
                          name="companyNumber"
                          onChange={formOption.companyNumber.onChange}
                          onBlur={formOption.companyNumber.onBlur}
                          innerRef={formOption.companyNumber.ref}
                          invalid={!!errors.companyNumber}
                        />
                      </Col>
                    </FormGroup>

                    <FormGroup>
                      <Label>입사일</Label>
                      <Col>
                        <Input
                          name="joinDate"
                          type="date"
                          onChange={formOption.joinDate.onChange}
                          onBlur={formOption.joinDate.onBlur}
                          innerRef={formOption.joinDate.ref}
                          invalid={!!errors.joinDate}
                        />
                      </Col>
                    </FormGroup>

                    <FormGroup tag="fieldset">
                      <legend className="col-form-label">*권한</legend>
                      <Col className="pt-2">
                        {state.roleList.map((role) => (
                          <FormGroup check inline key={`role_key_${role.id}`}>
                            <Input
                              type="radio"
                              name="roleId"
                              value={role.id}
                              onChange={formOption.roleId.onChange}
                              onBlur={formOption.roleId.onBlur}
                              innerRef={formOption.roleId.ref}
                              invalid={!!errors.roleId}
                            />
                            <Label>{role.name}</Label>
                          </FormGroup>
                        ))}
                      </Col>
                    </FormGroup>

                    <FormGroup tag="fieldset">
                      <legend className="col-form-label">*재직현황</legend>
                      <Col className="pt-2">
                        <FormGroup check inline>
                          <Input
                            type="radio"
                            name="state"
                            value="SUCCESS"
                            onChange={formOption.state.onChange}
                            onBlur={formOption.state.onBlur}
                            innerRef={formOption.state.ref}
                            invalid={!!errors.state}
                          />
                          <Label>재직</Label>
                        </FormGroup>
                        <FormGroup check inline>
                          <Input
                            type="radio"
                            name="state"
                            value="LEAVE"
                            onChange={formOption.state.onChange}
                            onBlur={formOption.state.onBlur}
                            innerRef={formOption.state.ref}
                            invalid={!!errors.state}
                          />
                          <Label>퇴사</Label>
                        </FormGroup>
                        <FormGroup check inline>
                          <Input
                            type="radio"
                            name="state"
                            value="TEMPORAL_LEAVE"
                            onChange={formOption.state.onChange}
                            onBlur={formOption.state.onBlur}
                            innerRef={formOption.state.ref}
                            invalid={!!errors.state}
                          />
                          <Label>휴직</Label>
                        </FormGroup>
                      </Col>
                    </FormGroup>

                    <Row>
                      <Col>
                        <div className="d-flex gap-2 justify-content-center">
                          <Button
                            color="secondary"
                            onClick={() => {
                              onClose(true)
                            }}
                          >
                            최소
                          </Button>
                          <Button type="submit" color="primary">
                            저장
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </>
      )}
    </>
  )
}

export default UserCompanyEditForm
