import React, { useEffect, useRef, useState } from 'react'
import { Link, useLocation, createSearchParams, useNavigate, useParams } from 'react-router-dom'
import MetaTags from 'react-meta-tags'
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  FormFeedback,
  Alert,
} from 'reactstrap'
import toastr from "toastr";
import { ContentState, convertToRaw, EditorState } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { useForm } from 'react-hook-form'
import { APIClient } from '../../lib/apiHelper'
import Breadcrumbs from '../../components/Common/Breadcrumb'

const NoticeForm = () => {
  const navigate = useNavigate()
  const locationParams = useParams()

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm()
  const [state, setState] = useState({
    notice: null as any,
    editorState: EditorState.createEmpty(),
  })

  const [formError, setFormError] = useState('')

  const formOption = {
    title: register('title', { required: true }),
    files: register('files', { required: false }),
  }

  useEffect(() => {
    if (locationParams.noticeId) {
      loadDetail(locationParams.noticeId)
    }
  }, [locationParams])

  const loadDetail = async (id: string) => {
    try {
      const { data: result } = await APIClient.get(`/board/notice/${id}`)

      if (result.result) {
        const notice = result.data.notice
  
        const contentBlock = htmlToDraft(notice.content)
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks)
        const editorState = EditorState.createWithContent(contentState)
  
        setState((prev: any) => {
          return {
            ...prev,
            notice: notice,
            editorState,
          }
        })
  
        const values = {
          title: notice.title,
        }
  
        reset(values)
      }
    } catch (error: any) {
      toastr.error(error.message)
    }
  }

  const onSubmit = async (data: any) => {
    console.log('onSubmit', data)

    const contentState = state.editorState.getCurrentContent()

    if (!contentState.hasText()) {
      setFormError('내용 입력해주세요')

      return
    } else {
      setFormError('')
    }

    const content = draftToHtml(convertToRaw(contentState))

    console.log('content:', content)

    const params = {
      title: data.title,
      content: content,
      files: data.files,
    }

    if (!locationParams.noticeId) {
      try {
        const { data: result } = await APIClient.post(`/board/notice`, params)

        if (result.result) {
          toastr.success('게시글 등록이 되었습니다')

          navigate('/board/notice')
        }
      } catch (error: any) {
        toastr.error(error.message)
      }
    } else {
      try {
        const { data: result } = await APIClient.put(`/board/notice/${locationParams.noticeId}`, params)

        if (result.result) {
          toastr.success('게시글 수정 되었습니다')

          navigate('/board/notice')
        }
      } catch (error: any) {
        toastr.error(error.message)
      }
    }
  }

  const onError = (d: any) => {
    console.error('error', d)
  }

  const onEditorStateChange = (editorState: any) => {
    setState((prev: any) => {
      return {
        ...prev,
        editorState,
      }
    })
  }

  const title = locationParams.noticeId ? '공지사항 수정' : '공지사항 등록'

  return (
    <>
      <div className="page-content">
        <MetaTags>
          <title>{title}</title>
        </MetaTags>

        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="공지사항" breadcrumbItem={title} />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  {formError && (
                    <Alert color="danger" role="alert">
                      {formError}
                    </Alert>
                  )}
                  <Form onSubmit={handleSubmit(onSubmit, onError)}>
                    <Row>
                      <Col xs={12}>
                        <FormGroup row>
                          <Label sm={2}>*제목</Label>
                          <Col sm={10}>
                            <Input
                              name="title"
                              onChange={formOption.title.onChange}
                              onBlur={formOption.title.onBlur}
                              innerRef={formOption.title.ref}
                              invalid={!!errors.title}
                            />
                          </Col>
                        </FormGroup>

                        <FormGroup row>
                          <Label sm={2}>*내용</Label>
                          <Col sm={10}>
                            <Editor
                              toolbarClassName="toolbarClassName"
                              wrapperClassName="wrapperClassName"
                              editorClassName="editorClassName"
                              placeholder="내용 입력..."
                              localization={{
                                locale: 'ko',
                              }}
                              editorState={state.editorState}
                              onEditorStateChange={onEditorStateChange}
                            />
                          </Col>
                        </FormGroup>

                        <FormGroup row>
                          <Label sm={2}>첨부파일</Label>
                          <Col sm={10}>
                            <Input
                              name="files"
                              onChange={formOption.files.onChange}
                              onBlur={formOption.files.onBlur}
                              innerRef={formOption.files.ref}
                              invalid={!!errors.files}
                            />
                          </Col>
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <div className="d-flex gap-2 justify-content-center">
                          <Button
                            color="secondary"
                            onClick={() => {
                              navigate(-1)
                            }}
                          >
                            최소
                          </Button>
                          <Button type="submit" color="primary">
                            저장
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default NoticeForm
