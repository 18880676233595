import React, { useEffect, useRef } from "react";

//Simple bar
import SimpleBar from "simplebar-react";

// MetisMenu
import MetisMenu from "metismenujs";
import { Link, useLocation } from "react-router-dom";

//i18n
import { useTranslation } from "react-i18next";

type SidebarContentProps = {
  // type: string;
};

const SidebarContent = (props: SidebarContentProps) => {
  const location = useLocation()
  const { t } = useTranslation();
  const ref = useRef<any>()

  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  // useEffect(() => {
  //   const pathName = location.pathname

  //   const initMenu = () => {
  //     console.log('initMenu:', pathName)
      
  //     new MetisMenu("#side-menu")
  //     let matchingMenuItem = null
  //     const ul = document.getElementById("side-menu")
  //     const items = ul!.getElementsByTagName("a")
  //     for (let i = 0; i < items.length; ++i) {
  //       if (pathName === items[i].pathname) {
  //         matchingMenuItem = items[i]
  //         break
  //       }
  //     }
  //     if (matchingMenuItem) {
  //       activateParentDropdown(matchingMenuItem)
  //     }
  //   }
  //   initMenu()
  // }, [location.pathname])

  // useEffect(() => {
  //   if (ref.current) {
  //     console.log('calc ref')
  //     ref.current.recalculate()
  //   }
  // })

  function scrollElement(item: any) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        if (ref.current) {
          ref.current.getScrollElement().scrollTop = currentPosition - 300
        }
      }
    }
  }

  function activateParentDropdown(item: any) {
    console.log('activateParentDropdown:', item)

    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]

    console.log('parent:', parent)
    console.log('parent2El:', parent2El)

    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          console.log('parent3:', parent3)

          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      //scrollElement(item);
      return false
    }
    //scrollElement(item);
    return false
  }

  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            {/* <li className="menu-title">{t("Menu")} </li> */}
            <li>
              <Link to="/dashboard">
                <i className="bx bx-home-circle" />
                <span>{t("대시보드")}</span>
              </Link>
            </li>
            <li>
              <Link to="/user" >
                <i className="bx bxs-user-detail" />
                <span>{t("회원 관리")}</span>
              </Link>
            </li>
            <li>
              <Link to="/company" >
              <i className='bx bx-buildings'></i>
                <span>{t("회사 관리")}</span>
              </Link>
            </li>            
            <li>
              <Link to="/#" className="has-arrow" >
                <i className='bx bx-lock-open-alt'></i>
                <span>{t("운영자 권한 관리")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/adminUser">{t("운영자 리스트")}</Link>
                </li>                
                <li>
                  <Link to="/adminUser/permission">{t("운영자 권한 설정")}</Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="/#" className="has-arrow">
                <i className='bx bx-list-ul'></i>
                <span>{t("게시판 관리")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/board/notice">{t("공지사항")}</Link>
                </li>
                <li>
                  <Link to="/board/question">{t("이용문의")}</Link>
                </li>
                <li>
                  <Link to="/board/serviceQuestion">{t("서비스 도입문의")}</Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="/#" className="has-arrow">
                <i className='bx bx-file'></i>
                <span>{t("약관 관리")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/terms/serviceTerm">{t("서비스 이용약관")}</Link>
                </li>
                <li>
                  <Link to="/terms/privacyPolicy">{t("개인정보 처리방침")}</Link>
                </li>
                <li>
                  <Link to="/terms/locationBasedServiceTerm">{t("위치기반서비스 이용약관")}</Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="/#" className="has-arrow">
                <i className='bx bx-message-square-dots'></i>
                <span>{t("카카오 알림톡")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/allimtalk/setting">{t("카카오 알림톡 설정")}</Link>
                </li>
                <li>
                  <Link to="/allimtalk/template">{t("카카오 알림톡 템플릿 설정")}</Link>
                </li>                
                <li>
                  <Link to="/allimtalk/sentList">{t("카카오 알림톡 발송 내역")}</Link>
                </li>                
              </ul>
            </li>
            <li>
              <Link to="#" >
                <i className="bx bx-receipt"></i>
                <span>{t("결제 관리")}</span>
              </Link>
            </li>
            <li>
              <Link to="/#" className="has-arrow">
                <i className='bx bx-line-chart'></i>
                <span>{t("통계")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/analytics/traffic">{t("방문자 분석")}</Link>
                </li>
                <li>
                  <Link to="/analytics/user">{t("회원 분석")}</Link>
                </li>                
              </ul>
            </li>


            
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

export default SidebarContent;
