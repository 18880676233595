const env = {
  test: {
    API_URL: 'http://localhost:11071/api/v1/back-office',
    CLIENT_ID: '1'
  },
  development: {
    API_URL: '/api/v1/back-office',
    CLIENT_ID: '1'
  },
  production: {
    API_URL: '/api/v1/back-office',
    CLIENT_ID: '1'
  },
}

export default env[process.env.NODE_ENV]
