import React, { useEffect } from "react";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";


// layouts
import VerticalLayout from "./components/VerticalLayout";
import NonAuthLayout from "./components/NonAuthLayout";

// Import scss
import "./assets/scss/theme.scss";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import {AuthProvider, useAuth} from './context/AuthContext'

import Dashboard from "./pages/dashboard/Dashboard";
import Login from "./pages/authentication/Login";
import Logout from "./pages/authentication/Logout";
import Register from "./pages/authentication/Register";
import UserProfile from "./pages/authentication/UserProfile";

import UserCompanyList from "./pages/user/UserCompanyList";
import UserCompanyAddForm from "./pages/user/UserCompanyAddForm";
import CompanyList from "./pages/company/CompanyList";
import CompanyAddForm from "./pages/company/CompanyAddForm";
import NoticeList from './pages/notice/NoticeList'
import NoticeForm from './pages/notice/NoticeForm'
import QuestionList from './pages/question/QuestionList'
import QuestionForm from './pages/question/QuestionForm'
import QuestionReplyForm from './pages/question/QuestionReplyForm'
import ServiceQuestionList from './pages/serviceQuestion/ServiceQuestionList'
import ServiceQuestionDetail from './pages/serviceQuestion/ServiceQuestionDetail'
import TermForm from './pages/terms/TermForm'
import AdminUserList from './pages/admin/AdminUserList'
import AdminUserAddForm from './pages/admin/AdminUserAddForm'
import Permission from './pages/permission/Permission'
import Setting from './pages/allimtalk/Setting'
import TemplateList from './pages/allimtalk/TemplateList'
import SentList from './pages/allimtalk/SentList'
import TrafficAnalytics from './pages/analytics/TrafficAnalytics'
import UserAnalytics from './pages/analytics/UserAnalytics'


const RequireAuth = ({ children }: { children: JSX.Element }) => {
  const {isAuthenticated} = useAuth();
  
  let location = useLocation();

  if (!isAuthenticated()) {
    console.log('no auth!')

    return <Navigate to="/login" state={{ from: location }} />;
  }

  return children;
};

const App = () => {
  useEffect(() => {
    toastr.options = ({
      closeButton: true
    })
  }, [])
  return (
    <AuthProvider>
      <Routes>
        {/* public routes */}
        <Route element={<NonAuthLayout />}>
          <Route path="/login" element={<Login />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/register" element={<Register />} />
        </Route>

        {/* protected routes */}
        <Route element={<VerticalLayout />}>
          <Route path="/" element={<RequireAuth><Navigate to="/dashboard" /></RequireAuth>} />
          <Route path="/dashboard" element={<RequireAuth><Dashboard /></RequireAuth>} />
          <Route path="/profile" element={<RequireAuth><UserProfile /></RequireAuth>} />

          <Route path="/user" element={<RequireAuth><UserCompanyList /></RequireAuth>} />
          <Route path="/user/add" element={<RequireAuth><UserCompanyAddForm /></RequireAuth>} />

          <Route path="/company" element={<RequireAuth><CompanyList /></RequireAuth>} />
          <Route path="/company/add" element={<RequireAuth><CompanyAddForm /></RequireAuth>} />

          <Route path="/board/notice" element={<RequireAuth><NoticeList /></RequireAuth>} />
          <Route path="/board/notice/add" element={<RequireAuth><NoticeForm /></RequireAuth>} />
          <Route path="/board/notice/edit/:noticeId" element={<RequireAuth><NoticeForm /></RequireAuth>} />

          <Route path="/board/question" element={<RequireAuth><QuestionList /></RequireAuth>} />
          <Route path="/board/question/add" element={<RequireAuth><QuestionForm /></RequireAuth>} />
          <Route path="/board/question/edit/:questionId" element={<RequireAuth><QuestionForm /></RequireAuth>} />
          <Route path="/board/question/reply/:questionId" element={<RequireAuth><QuestionReplyForm /></RequireAuth>} />

          <Route path="/board/serviceQuestion" element={<RequireAuth><ServiceQuestionList /></RequireAuth>} />
          <Route path="/board/serviceQuestion/:questionId" element={<RequireAuth><ServiceQuestionDetail /></RequireAuth>} />

          <Route path="/terms/serviceTerm" element={<RequireAuth><TermForm termsType="SERVICE_TERM" /></RequireAuth>} />
          <Route path="/terms/serviceTerm/:termsId" element={<RequireAuth><TermForm termsType="SERVICE_TERM" /></RequireAuth>} />
          <Route path="/terms/privacyPolicy" element={<RequireAuth><TermForm termsType="PRIVACY_POLICY" /></RequireAuth>} />
          <Route path="/terms/privacyPolicy/:termsId" element={<RequireAuth><TermForm termsType="PRIVACY_POLICY" /></RequireAuth>} />
          <Route path="/terms/locationBasedServiceTerm" element={<RequireAuth><TermForm termsType="LOCATION_BASED_SERVICE" /></RequireAuth>} />
          <Route path="/terms/locationBasedServiceTerm/:termsId" element={<RequireAuth><TermForm termsType="LOCATION_BASED_SERVICE" /></RequireAuth>} />
          
          <Route path="/adminUser" element={<RequireAuth><AdminUserList /></RequireAuth>} />
          <Route path="/adminUser/add" element={<RequireAuth><AdminUserAddForm /></RequireAuth>} />
          <Route path="/adminUser/permission" element={<RequireAuth><Permission /></RequireAuth>} />

          <Route path="/allimtalk/setting" element={<RequireAuth><Setting /></RequireAuth>} />
          <Route path="/allimtalk/template" element={<RequireAuth><TemplateList /></RequireAuth>} />
          <Route path="/allimtalk/sentList" element={<RequireAuth><SentList /></RequireAuth>} />
          
          <Route path="/analytics/traffic" element={<RequireAuth><TrafficAnalytics /></RequireAuth>} />
          <Route path="/analytics/user" element={<RequireAuth><UserAnalytics /></RequireAuth>} />
          
        </Route>

      </Routes>
    </AuthProvider>
  );
};

export default App;
