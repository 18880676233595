import { Ability, PureAbility } from '@casl/ability'
import React, { useEffect } from 'react'
import { APIClient } from '../lib/apiHelper'
import { getItem, removeItem } from '../lib/localstorage'
import {AbilityContext} from './Can'
import defaultAbility, { updateAbility } from '../lib/ability'

interface AuthContextType {
  user: any
  isAuthenticated: () => boolean
  login: (email: string, password: string) => Promise<void>
  logout: (callback: VoidFunction) => void
}

const AuthContext = React.createContext<AuthContextType>(null!)

const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [user, setUser] = React.useState<any>(null)
  const [ability, setAbility] = React.useState(defaultAbility)


  //reload 시 사용자 정보 로딩
  useEffect(() => {
    console.log('AuthProvider onload')

    loadUserProfile()
  }, [])

  const loadUserProfile = async () => {
    if (isAuthenticated() && !user) {
      try {
        const { data: result } = await APIClient.get('/auth/me')

        console.log('logged in user:', result.data.user)
  
        setUser(result.data.user)
  
        //update ability
        updateAbility(ability, result.data.user)
      } catch (error: any) {
        toastr.error(error.message)
      }
    }
  }

  const isAuthenticated = () => {
    const accessToken = getItem('access-token')
    const refreshToken = getItem('refresh-token')

    if (accessToken && refreshToken) {
      return true
    }

    return false
  }

  const login = async (email: string, password: string) => {
    const params = {
      email,
      password,
    }

    const { data: result } = await APIClient.post('/auth/login', params)

    console.log('logged in user:', result.data.user)

    setUser(result.data.user)

    //update ability
    updateAbility(ability, result.data.user)
  }

  const logout = (callback: VoidFunction) => {
    removeItem('access-token')
    removeItem('refresh-token')
    setUser(null)

    //update ability
    updateAbility(ability, null)

    callback()
  }

  let value = { user, isAuthenticated, login, logout }

  return (<AuthContext.Provider value={value}>
    <AbilityContext.Provider value={ability}>
      {children}
    </AbilityContext.Provider>
  </AuthContext.Provider>)
}

const useAuth = () => {
  return React.useContext(AuthContext)
}

export { AuthContext, AuthProvider, useAuth }
