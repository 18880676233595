import { Link, useLocation, createSearchParams, useNavigate, useParams } from 'react-router-dom'
import MetaTags from 'react-meta-tags'
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  FormFeedback,
  Table,
} from 'reactstrap'
import toastr from "toastr";
import { useForm } from 'react-hook-form'
import BootstrapTable from 'react-bootstrap-table-next'
import { APIClient } from '../../lib/apiHelper'
import { useEffect, useState } from 'react'
import moment from 'moment'
import SweetAlert from 'react-bootstrap-sweetalert'

interface TermsListProps {
  //selectedId: number | null
  termsType: string
  onClose: (reload: boolean) => void
}

const TermsList = ({ termsType, onClose }: TermsListProps) => {
  const navigate = useNavigate()
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm()
  const [state, setState] = useState({
    termsList: [] as any[],
    termsConfig: null as any,
    selected: [] as any[],
  })
  const [alert, setAlert] = useState<any>(null)

  const columns = [
    {
      text: '번호',
      dataField: 'id',
      sort: true,
      formatter: (cell: any, row: any) => {
        return <>{row.id}</>
      },
    },
    {
      dataField: 'visible',
      text: '노출',
      //filter: textFilter(),
      sort: true,
      formatter: (cell: any, row: any) => {
        return <span>..</span>
      },
    },
    {
      dataField: 'name',
      text: '약관명',
      //filter: textFilter(),
      sort: true,
      formatter: (cell: any, row: any) => {
        return <span>{row.name}</span>
      },
    },
    {
      dataField: 'termsDate',
      text: '등록일',
      //filter: textFilter(),
      sort: true,
      formatter: (cell: any, row: any) => {
        return <span>{moment(row.termsDate).format('YYYY-MM-DD')}</span>
      },
    },
    {
      dataField: 'action',
      isDummyField: true,
      text: '관리',
      editable: false,
      formatter: (cell: any, row: any) => (
        <div className="d-flex gap-3">
          <Button size="sm" color="light" onClick={() => viewTerms(row.id)}>
            보기
          </Button>
        </div>
      ),
    },
  ]

  useEffect(() => {
    loadTermsList()
  }, [])

  const loadTermsList = async () => {
    try {
      const { data: result } = await APIClient.get(`/terms?type=${termsType}`)

      console.log('loadTermsList:', result)
  
      if (result.result) {
        const termsList = result.data.termsList
  
        setState({ ...state, termsList, termsConfig: result.data.termsConfig })
  
        reset({
          showPreviousTerms: result.data.termsConfig.showPreviousTerms ? 'true' : 'false'
        })
      }
    } catch (error: any) {
      toastr.error(error.message)
    }
  }

  const onSubmit = async (data: any) => {
    console.log('onSubmit', data)

    const params = {
      type: termsType,
      showPreviousTerms: data.showPreviousTerms === 'true'
    }

    try {
      const { data: result } = await APIClient.put(`/terms/config`, params)

      if (result.result) {
        toastr.success('약관 설정 저장 되었습니다')

        onClose(false)
      }
    } catch (error: any) {
      toastr.error(error.message)
    }
  }

  const onError = (d: any) => {
    console.error('error', d)
  }

  const formOption = {
    showPreviousTerms: register('showPreviousTerms', { required: true }),

  }

  const handleDeleteList = () => {
    console.log('handleDeleteList')

    if (state.selected.length === 0) {
      return
    }

    const getAlert = () => (
      <SweetAlert
        title="정말 삭제 하시겠니까?"
        warning
        showCancel
        confirmBtnText="네, 삭제합니다."
        cancelBtnText="아니에요"
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="danger"
        onConfirm={() => {
          deleteTermsList()
          setAlert(null)
          console.log('confirm')
        }}
        onCancel={() => setAlert(null)}
      >
        삭제된 약관들을 복원할 수 없습니다!
      </SweetAlert>
    )

    setAlert(getAlert())
  }

  const deleteTermsList = async () => {
    const params = {
      ids: state.selected,
    }

    try {
      const { data: result } = await APIClient.post(`/terms/deleteTermsList`, params)

      if (result.result) {
        toastr.success('선택된 약관들이 삭제 되었습니다')
  
        setState((prev: any) => {
          return { ...prev, selected: [] }
        })
  
        //reload
        loadTermsList()
      }
    } catch (error: any) {
      toastr.error(error.message)
    }
  }

  const viewTerms = (id: number) => {
    onClose(false)

    navigate(`/terms/serviceTerm/${id}`)
  }

  return (
    <>
      <Container fluid>
        <Card>
          <CardBody>
            <Form onSubmit={handleSubmit(onSubmit, onError)}>
              <FormGroup row tag="fieldset">
                <legend className="col-form-label col-sm-3">이전 약관 공개여부</legend>
                <Col sm={9} className="pt-2">
                  <FormGroup check inline>
                    <Input
                      type="radio"
                      name="showPreviousTerms"
                      value="true"
                      onChange={formOption.showPreviousTerms.onChange}
                      onBlur={formOption.showPreviousTerms.onBlur}
                      innerRef={formOption.showPreviousTerms.ref}
                      invalid={!!errors.showPreviousTerms}
                    />
                    <Label>공개</Label>
                  </FormGroup>
                  <FormGroup check inline>
                    <Input
                      type="radio"
                      name="showPreviousTerms"
                      value="false"
                      onChange={formOption.showPreviousTerms.onChange}
                      onBlur={formOption.showPreviousTerms.onBlur}
                      innerRef={formOption.showPreviousTerms.ref}
                      invalid={!!errors.showPreviousTerms}
                    />
                    <Label>비공개</Label>
                  </FormGroup>
                </Col>
              </FormGroup>

              <FormGroup row>
                <Label sm={3}>약관 리스트</Label>
                <Col sm={9}>
                  <BootstrapTable
                    columns={columns}
                    data={state.termsList}
                    keyField="id"
                    selectRow={{
                      mode: 'checkbox',
                      selected: state.selected,
                      onSelect: (row: any, isSelected: boolean, rowIndex: number, e: any) => {
                        if (isSelected) {
                          setState((prev: any) => {
                            return { ...prev, selected: [...prev.selected, row.id] }
                          })
                        } else {
                          setState((prev: any) => {
                            return { ...prev, selected: prev.selected.filter((item: any) => item !== row.id) }
                          })
                        }
                      },
                      onSelectAll: (isSelected: boolean, rows: any[]) => {
                        const ids = rows.map((r) => r.id)
                        if (isSelected) {
                          setState((prev: any) => {
                            return { ...prev, selected: ids }
                          })
                        } else {
                          setState((prev: any) => {
                            return { ...prev, selected: [] }
                          })
                        }
                      },
                    }}
                    responsive
                  />
                  <div className="d-flex justify-content-between mt-3">
                    <div className="d-flex gap-2">
                      <Button size="sm" color="light">
                        <i className="bx bx-up-arrow-alt fs-5"></i>
                      </Button>
                      <Button size="sm" color="light">
                        <i className="bx bx-down-arrow-alt fs-5"></i>
                      </Button>
                    </div>
                    <div className="d-flex gap-2">
                      <Button size="sm" type="button" color="danger" onClick={handleDeleteList}>
                        삭제
                      </Button>
                    </div>
                  </div>
                </Col>
              </FormGroup>

              <Row>
                <Col sm={4}></Col>
                <Col sm={8}>
                  <div className="d-flex gap-2 justify-content-start">
                    <Button
                      color="secondary"
                      onClick={() => {
                        onClose(false)
                      }}
                    >
                      최소
                    </Button>
                    <Button type="submit" color="primary">
                      저장
                    </Button>
                  </div>
                </Col>
              </Row>
            </Form>
          </CardBody>
        </Card>
      </Container>

      {alert}
    </>
  )
}

export default TermsList
