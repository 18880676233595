import React, { useEffect, useRef, useState } from 'react'
import { Link, useLocation, createSearchParams, useNavigate } from 'react-router-dom'
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
} from 'reactstrap'
import toastr from "toastr";
import { useForm } from 'react-hook-form'
import { APIClient } from '../../lib/apiHelper'
import moment from 'moment'

interface CompanyEditFormProps {
  selectedId: number | null
  onClose: (reload: boolean) => void
}

const CompanyEditForm = ({ selectedId, onClose }: CompanyEditFormProps) => {
  const navigate = useNavigate()
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm()

  const [state, setState] = useState({
    company: null as any,
  })

  const [changePassword, setChangePassword] = useState(false)

  const formOption = {
    name: register('name', { required: true }),
    ceoName: register('ceoName', { required: true }),
    planId: register('planId', { required: true }),
    registrationNumber: register('registrationNumber', { required: true }),
    tel: register('tel', { required: true }),
    contractStartAt: register('contractStartAt', { required: false }),
    contractEndAt: register('contractEndAt', { required: false }),

    adminEmail: register('adminEmail', { required: false }),
    adminName: register('adminName', { required: false }),
    adminPassword: register('adminPassword', { required: false }),
    adminPhone: register('adminPhone', { required: false }),    
  }

  useEffect(() => {
    if (selectedId) {
      loadDetail(selectedId)
    }
  }, [selectedId])

  const loadDetail = async (companyId: number) => {
    try {
      const { data: result } = await APIClient.get(`/company/${companyId}`)

      if (result.result) {
        const company = result.data.company
  
        console.log('company:', company)
  
        setState({
          ...state,
          company: company,
        })
  
        //set form data
        const values = {
          name: company.name,
          ceoName: company.ceoName,
          planId: company.planId,
          registrationNumber: company.registrationNumber,
          tel: company.tel,
          contractStartAd: company.contractStartAd,
          contractEndAd: company.contractEndAd,
  
          adminEmail: company.admin?.email,
          adminName: company.admin?.name,
          adminPhone: company.admin?.phone,
        }
  
        console.log('values:', values)
  
        reset(values)
      }
    } catch (error: any) {
      toastr.error(error.message)
    }
  }

  const onSubmit = async (data: any) => {
    console.log('onSubmit', data)

    const params = {
      name: data.name,
      ceoName: data.ceoName,
      registrationNumber: data.registrationNumber,
      tel: data.tel,
      planId: data.planId,
      contractStartAd: data.contractStartAd,
      contractEndAd: data.contractEndAd,

      admin: {
        id: state.company.admin?.id,
        email: data.adminEmail,
        name: data.adminName,
        password: data.adminPassword,
        phone: data.adminPhone,
      },
    }

    try {
      const { data: result } = await APIClient.put(`/company/${state.company.id}`, params)

      if (result.result) {
        toastr.success('회사 정보 수정 되었습니다')
  
        onClose(true)
      }
    } catch (error: any) {
      toastr.error(error.message)
    }
    
  }

  const onError = (d: any) => {
    console.error('error', d)
  }

  return (
    <>
      {state.company && (
        <>
          <Container fluid>
            {/* <Row>
              <Col> */}
                <Card>
                  <CardBody>
                    <Form onSubmit={handleSubmit(onSubmit, onError)}>
                      <Row>
                        <Col xs={12} >
                          <FormGroup row>
                            <Label sm={4}>*회사명</Label>
                            <Col sm={8}>
                              <Input
                                name="name"
                                onChange={formOption.name.onChange}
                                onBlur={formOption.name.onBlur}
                                innerRef={formOption.name.ref}
                                invalid={!!errors.name}
                              />
                            </Col>
                          </FormGroup>
                          <FormGroup row>
                            <Label sm={4}>*사업자번호</Label>
                            <Col sm={8}>
                              <Input
                                name="registrationNumber"
                                onChange={formOption.registrationNumber.onChange}
                                onBlur={formOption.registrationNumber.onBlur}
                                innerRef={formOption.registrationNumber.ref}
                                invalid={!!errors.registrationNumber}
                              />
                            </Col>
                          </FormGroup>
                          <FormGroup row>
                            <Label sm={4}>*대표자명</Label>
                            <Col sm={8}>
                              <Input
                                name="ceoName"
                                onChange={formOption.ceoName.onChange}
                                onBlur={formOption.ceoName.onBlur}
                                innerRef={formOption.ceoName.ref}
                                invalid={!!errors.ceoName}
                              />
                            </Col>
                          </FormGroup>
                          <FormGroup row>
                            <Label sm={4}>*회사연락처</Label>
                            <Col sm={8}>
                              <Input
                                name="tel"
                                onChange={formOption.tel.onChange}
                                onBlur={formOption.tel.onBlur}
                                innerRef={formOption.tel.ref}
                                invalid={!!errors.tel}
                              />
                            </Col>
                          </FormGroup>
                          <FormGroup row tag="fieldset">
                            <legend className="col-form-label col-sm-4">*이용요금</legend>
                            <Col sm={8} className="pt-2">
                              <FormGroup check inline>
                                <Input
                                  type="radio"
                                  name="planId"
                                  value="1"
                                  onChange={formOption.planId.onChange}
                                  onBlur={formOption.planId.onBlur}
                                  innerRef={formOption.planId.ref}
                                  invalid={!!errors.planId}
                                />
                                <Label>유료1</Label>
                              </FormGroup>
                              <FormGroup check inline>
                                <Input
                                  type="radio"
                                  name="planId"
                                  value="2"
                                  onChange={formOption.planId.onChange}
                                  onBlur={formOption.planId.onBlur}
                                  innerRef={formOption.planId.ref}
                                  invalid={!!errors.planId}
                                />
                                <Label>유료2</Label>
                              </FormGroup>
                            </Col>
                          </FormGroup>
                          <FormGroup row>
                            <Label sm={4}>계약기간</Label>
                            <Col sm={8}>
                              <div className="d-flex flex-row gap-3">
                                <Input
                                  name="contractStartAt"
                                  type="date"
                                  onChange={formOption.contractStartAt.onChange}
                                  onBlur={formOption.contractStartAt.onBlur}
                                  innerRef={formOption.contractStartAt.ref}
                                  invalid={!!errors.contractStartAt}
                                />
                                <div className="d-flex align-items-center">
                                  <span>~</span>
                                </div>
                                <Input
                                  name="contractEndAt"
                                  type="date"
                                  onChange={formOption.contractEndAt.onChange}
                                  onBlur={formOption.contractEndAt.onBlur}
                                  innerRef={formOption.contractEndAt.ref}
                                  invalid={!!errors.contractEndAt}
                                />
                              </div>
                            </Col>
                            {/* <Col sm={4}>
                            
                          </Col> */}
                          </FormGroup>
                          <FormGroup row>
                            <Label sm={4}>최고관리자ID</Label>
                            <Col sm={8}>
                              <Input
                                name="adminEmail"
                                type="email"
                                readOnly
                                onChange={formOption.adminEmail.onChange}
                                onBlur={formOption.adminEmail.onBlur}
                                innerRef={formOption.adminEmail.ref}
                                invalid={!!errors.adminEmail}
                              />
                            </Col>
                          </FormGroup>
                          <FormGroup row>
                            <Label sm={4}>최고관리자PW</Label>
                            <Col sm={5}>
                              <Input
                                name="adminPassword"
                                type="password"
                                readOnly={!changePassword}
                                onChange={formOption.adminPassword.onChange}
                                onBlur={formOption.adminPassword.onBlur}
                                innerRef={formOption.adminPassword.ref}
                                invalid={!!errors.adminPassword}
                              />
                            </Col>
                            <Col sm={3}>
                              <Button type="button" outline onClick={() => {setChangePassword(true)}} >초기화</Button>
                            </Col>
                          </FormGroup>
                          <FormGroup row>
                            <Label sm={4}>최고관리자 이름</Label>
                            <Col sm={8}>
                              <Input
                                name="adminName"
                                readOnly
                                onChange={formOption.adminName.onChange}
                                onBlur={formOption.adminName.onBlur}
                                innerRef={formOption.adminName.ref}
                                invalid={!!errors.adminName}
                              />
                            </Col>
                          </FormGroup>
                          <FormGroup row>
                            <Label sm={4}>최고관리자 연락처</Label>
                            <Col sm={8}>
                              <Input
                                name="adminPhone"
                                readOnly
                                onChange={formOption.adminPhone.onChange}
                                onBlur={formOption.adminPhone.onBlur}
                                innerRef={formOption.adminPhone.ref}
                                invalid={!!errors.adminPhone}
                              />
                            </Col>
                          </FormGroup>
                        </Col>
                        <Col xs={12} >
                          
                          
                        </Col>
                      </Row>

                      <Row>
                        <Col>
                          <div className="d-flex gap-2 justify-content-center">
                            <Button
                              color="secondary"
                              onClick={() => {
                                onClose(false)
                              }}
                            >
                              최소
                            </Button>
                            <Button type="submit" color="primary">
                              저장
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
              {/* </Col>
            </Row> */}
          </Container>
        </>
      )}
    </>
  )
}

export default CompanyEditForm
