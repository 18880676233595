import { Link, useLocation, useNavigate } from 'react-router-dom'
import MetaTags from 'react-meta-tags';
import toastr from "toastr";
import { useAuth } from '../../context/AuthContext'

import { Row, Col, CardBody, Card, Container } from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"

import profile from "../../assets/images/profile-img.png"
import logo from "../../assets/images/logo.svg"
import lightlogo from "../../assets/images/logo-light.svg"


const Login = () => {
  let navigate = useNavigate()
  let location = useLocation()
  let { login } = useAuth()

  let from = location.state?.from?.pathname || '/'

  const handleValidSubmit = async (e: any, values: any) => {
    console.log(values)

    try {
      await login(values.email, values.password)

      navigate(from, { replace: true })
    } catch (error: any) {
      toastr.error(error.message)
    }
  }

  return (
    <>
      <MetaTags>
        <title>Login | Pinat</title>
      </MetaTags>

      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col className="col-7">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Welcome Back !</h5>
                        <p>Sign in to continue to Pinat.</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  {/* <div className="auth-logo">
                    <Link to="/" className="auth-logo-light">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img src={lightlogo} alt="" className="rounded-circle" height="34" />
                        </span>
                      </div>
                    </Link>
                    <Link to="/" className="auth-logo-dark">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img src={logo} alt="" className="rounded-circle" height="34" />
                        </span>
                      </div>
                    </Link>
                  </div> */}
                  <div className="p-2">
                    <AvForm className="form-horizontal" onValidSubmit={handleValidSubmit}>
                      <div className="mb-3">
                        <AvField
                          name="email"
                          label="이메일"
                          value=""
                          className="form-control"
                          placeholder="이메일 주소"
                          type="text"
                          validate={{
                            required: {value: true, errorMessage: '이메일 주소 입력해 주세요'},
                            pattern: {value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/, errorMessage: '유효한 이메일 주소 입력해 주세요'}
                          }}
                        />
                      </div>

                      <div className="mb-3">
                        <AvField name="password" label="비밀번호" value="" type="password" placeholder="비밀번호" validate={{
                          required: {value: true, errorMessage: '비밀번호를 입력해 주세요'}
                        }} />
                      </div>

                      <div className="form-check">
                        <input type="checkbox" className="form-check-input" id="customControlInline" />
                        <label className="form-check-label" htmlFor="customControlInline">
                          로그인 상태 유지
                        </label>
                      </div>

                      <div className="mt-3 d-grid">
                        <button className="btn btn-primary btn-block " type="submit">
                          로그인
                        </button>
                      </div>

                      <div className="mt-4 text-center">
                        <h5 className="font-size-14 mb-3">Sign in with</h5>

                        <ul className="list-inline">
                          <li className="list-inline-item">
                            <Link to="#" className="social-list-item bg-primary text-white border-primary">
                              <i className="mdi mdi-facebook" />
                            </Link>
                          </li>{' '}
                          <li className="list-inline-item">
                            <Link to="#" className="social-list-item bg-info text-white border-info">
                              <i className="mdi mdi-twitter" />
                            </Link>
                          </li>{' '}
                          <li className="list-inline-item">
                            <Link to="#" className="social-list-item bg-danger text-white border-danger">
                              <i className="mdi mdi-google" />
                            </Link>
                          </li>
                        </ul>
                      </div>

                      <div className="mt-4 text-center">
                        <Link to="/#" className="text-muted">
                          <i className="mdi mdi-lock me-1" /> 비밀번호 찾기
                        </Link>
                      </div>
                    </AvForm>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                {/* <p>
                  Don&apos;t have an account ?{' '}
                  <Link to="pages-register" className="fw-medium text-primary">
                    {' '}
                    Signup now{' '}
                  </Link>{' '}
                </p> */}
                <p>
                  © {new Date().getFullYear()} © Korean Global.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default Login
