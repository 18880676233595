import React, { useEffect, useRef, useState } from 'react'
import { Link, useLocation, createSearchParams, useNavigate } from 'react-router-dom'
import MetaTags from 'react-meta-tags'
import { Card, CardBody, Col, Container, Row, Button, Form, FormGroup, Label, Input, FormText, FormFeedback } from 'reactstrap'
import toastr from 'toastr'
import Breadcrumbs from '../../components/Common/Breadcrumb'
import { useForm, Controller } from 'react-hook-form'
import Select from 'react-select'
import { APIClient } from '../../lib/apiHelper'

const AdminUserAddForm = () => {
  const navigate = useNavigate()
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm()
  const [state, setState] = useState({
    adminRoleList: [] as any[],
  })

  useEffect(() => {
    loadAdminRoleList()
  }, [])

  const loadAdminRoleList = async () => {
    try {
      const { data: result } = await APIClient.get('/adminRole')

      console.log('loadAdminRoleList:', result)

      const adminRoleList = result.data.adminRoleList.map((item: any) => {
        return {
          id: item.id,
          value: item.id,
          label: item.name,
        }
      })

      console.log('adminRoleList:', adminRoleList)

      if (result.result) {
        setState((prev: any) => {
          return {
            ...prev,
            adminRoleList,
          }
        })
      }
    } catch (error: any) {
      toastr.error(error.message)
    }
  }

  const onSubmit = async (data: any) => {
    console.log('onSubmit', data)

    const params = {
      email: data.email,
      password: data.password,
      name: data.name,
      phone: data.phone,

      companyName: data.companyName,
      departName: data.departName,
      rankName: data.rankName,
      adminRoleId: data.adminRoleId,
    }

    try {
      const { data: result } = await APIClient.post(`/adminUser`, params)

      if (result.result) {
        toastr.success('운영자 등록 되었습니다')
        navigate('/adminUser')
      }
    } catch (error: any) {
      toastr.error(error.message)
    }
  }

  const onError = (d: any) => {
    console.error('error', d)
  }

  const validateEmail = async (v: string) => {
    console.log('v:', v)
    if (!v) {
      return false
    }

    try {
      if (v) {
        const { data: result } = await APIClient.get(`/adminUser/searchId?email=${v}`)

        console.log('result:', result)

        if (result.result) {
          return result.data.count === 0 || '사용 불가능한 이메일입니다'
        }
      }
    } catch (error: any) {
      toastr.error(error.message)
    }

    return false
  }

  const formOption = {
    email: register('email', {
      required: true,
      pattern: { value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/, message: '유효한 이메일을 입력해주세요' },
      validate: validateEmail,
    }),
    name: register('name', { required: true }),
    phone: register('phone', { required: true }),
    password: register('password', { required: true }),

    companyName: register('companyName', { required: true }),
    departName: register('departName', { required: false }),
    rankName: register('rankName', { required: false }),
    adminRoleId: register('adminRoleId', { required: true }),
  }

  const checkEmail = () => {
    console.log('checkEmail')
  }

  return (
    <>
      <div className="page-content">
        <MetaTags>
          <title>운영자 등록</title>
        </MetaTags>

        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="운영자 권한 관리" breadcrumbItem="운영자 등록" />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <Form onSubmit={handleSubmit(onSubmit, onError)}>
                    <Row>
                      <Col xs={5}>
                        <FormGroup row>
                          <Label sm={4}>*아이디(이메일)</Label>
                          <Col sm={8}>
                            <Input
                              name="email"
                              type="email"
                              onChange={formOption.email.onChange}
                              onBlur={formOption.email.onBlur}
                              innerRef={formOption.email.ref}
                              invalid={!!errors.email}
                            />
                            {errors.email && <FormText color="danger"> {errors.email.message} </FormText>}
                          </Col>
                          {/* <Col sm={2}>
                            <Button outline onClick={checkEmail}>
                              조회
                            </Button>
                          </Col> */}
                        </FormGroup>
                      </Col>
                      <Col xs={1} />
                      <Col xs={5}>
                        <FormGroup row>
                          <Label sm={4}>*비밀번호</Label>
                          <Col sm={8}>
                            <Input
                              name="password"
                              type="password"
                              onChange={formOption.password.onChange}
                              onBlur={formOption.password.onBlur}
                              innerRef={formOption.password.ref}
                              invalid={!!errors.password}
                            />
                          </Col>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={5}>
                        <FormGroup row>
                          <Label sm={4}>*이름</Label>
                          <Col sm={8}>
                            <Input
                              name="name"
                              onChange={formOption.name.onChange}
                              onBlur={formOption.name.onBlur}
                              innerRef={formOption.name.ref}
                              invalid={!!errors.name}
                            />
                          </Col>
                        </FormGroup>
                      </Col>
                      <Col xs={1} />
                      <Col xs={5}>
                        <FormGroup row>
                          <Label sm={4}>*회사명</Label>
                          <Col sm={8}>
                            <Input
                              name="companyName"
                              onChange={formOption.companyName.onChange}
                              onBlur={formOption.companyName.onBlur}
                              innerRef={formOption.companyName.ref}
                              invalid={!!errors.companyName}
                            />
                          </Col>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={5}>
                        <FormGroup row>
                          <Label sm={4}>*휴대폰전화</Label>
                          <Col sm={8}>
                            <Input
                              name="phone"
                              onChange={formOption.phone.onChange}
                              onBlur={formOption.phone.onBlur}
                              innerRef={formOption.phone.ref}
                              invalid={!!errors.phone}
                            />
                          </Col>
                        </FormGroup>
                      </Col>
                      <Col xs={1} />
                      <Col xs={5}>
                        <FormGroup row>
                          <Label sm={4}>직급</Label>
                          <Col sm={8}>
                            <Input
                              name="rankName"
                              onChange={formOption.rankName.onChange}
                              onBlur={formOption.rankName.onBlur}
                              innerRef={formOption.rankName.ref}
                              invalid={!!errors.rankName}
                            />
                          </Col>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={5}>
                        <FormGroup row>
                          <Label sm={4}>부서명</Label>
                          <Col sm={8}>
                            <Input
                              name="departName"
                              onChange={formOption.departName.onChange}
                              onBlur={formOption.departName.onBlur}
                              innerRef={formOption.departName.ref}
                              invalid={!!errors.departName}
                            />
                          </Col>
                        </FormGroup>
                      </Col>
                      <Col xs={1} />
                      <Col xs={5}>
                        <FormGroup row>
                          <Label sm={4}>*권한</Label>
                          <Col sm={8}>
                            <Input
                              name="adminRoleId"
                              type="select"
                              onChange={formOption.adminRoleId.onChange}
                              onBlur={formOption.adminRoleId.onBlur}
                              innerRef={formOption.adminRoleId.ref}
                              invalid={!!errors.adminRoleId}
                            >
                              {state.adminRoleList.map((item) => (
                                <option value={item.id}>{item.label}</option>
                              ))}
                            </Input>
                          </Col>
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <div className="d-flex gap-2 justify-content-center">
                          <Button
                            color="secondary"
                            onClick={() => {
                              navigate(-1)
                            }}
                          >
                            최소
                          </Button>
                          <Button type="submit" color="primary">
                            저장
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default AdminUserAddForm
