import React, { useEffect, useRef, useState } from 'react'
import { Link, useLocation, createSearchParams, useNavigate, useParams } from 'react-router-dom'
import MetaTags from 'react-meta-tags'
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  FormFeedback,
} from 'reactstrap'
import toastr from "toastr";
import { useForm } from 'react-hook-form'
import { APIClient } from '../../lib/apiHelper'
import Breadcrumbs from '../../components/Common/Breadcrumb'

const QuestionReplyForm = () => {
  const navigate = useNavigate()
  const locationParams = useParams()

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm()
  const [state, setState] = useState({
    question: null as any,
    questionCategoryList: [] as any[],
  })

  const formOption = {
    answer: register('answer', { required: true }),
  }

  useEffect(() => {
    loadFormData()
  }, [])

  useEffect(() => {
    if (locationParams.questionId) {
      loadDetail(locationParams.questionId)
    }
  }, [locationParams])

  const loadFormData = async () => {
    try {
      const { data: result } = await APIClient.get(`/board/question/category`)

      if (result.result) {
        console.log('category list:', result.data.questionCategoryList)
        setState((prev: any) => {
          return {
            ...prev,
            questionCategoryList: result.data.questionCategoryList,
          }
        })
      }
    } catch (error: any) {
      toastr.error(error.message)
    }
  }

  const loadDetail = async (id: string) => {
    try {
      const { data: result } = await APIClient.get(`/board/question/${id}`)

      if (result.result) {
        const question = result.data.question
  
        console.log('question:', question)
  
        setState((prev: any) => {
          return {
            ...prev,
            question: question,
          }
        })
  
        const values = {
          answer: '',
        }
  
        reset(values)
      }
    } catch (error: any) {
      toastr.error(error.message)
    }
  }

  const onSubmit = async (data: any) => {
    console.log('onSubmit', data)

    const params = {
      answer: data.answer,
    }

    try {
      const { data: result } = await APIClient.post(`/board/question/reply/${locationParams.questionId}`, params)

      if (result.result) {
        toastr.success('답변 등록 되었습니다')

        navigate('/board/question')
      }
    } catch (error: any) {
      toastr.error(error.message)
    }
  }

  const onError = (d: any) => {
    console.error('error', d)
  }

  return (
    <>
      <div className="page-content">
        <MetaTags>
          <title>이용문의 답변 등록</title>
        </MetaTags>

        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="이용문의" breadcrumbItem={'이용문의 답변 등록'} />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <Form onSubmit={handleSubmit(onSubmit, onError)}>
                    <Row>
                      <Col xs={12}>
                        <b>문의 내용</b>
                        <hr />
                      </Col>
                      <Col xs={12}>
                        <FormGroup row>
                          <Label sm={2}>작성자/소속</Label>
                          <Col sm={10}>{state.question?.user?.name} / {state.question?.user?.currentCompany?.name}</Col>
                        </FormGroup>

                        <FormGroup row>
                          <Label sm={2}>이메일</Label>
                          <Col sm={10}>{state.question?.userEmail}</Col>
                        </FormGroup>

                        <FormGroup row>
                          <Label sm={2}>문의유형</Label>
                          <Col sm={10}>{state.question?.questionCategory?.name}</Col>
                        </FormGroup>

                        <FormGroup row>
                          <Label sm={2}>제목</Label>
                          <Col sm={10}>{state.question?.title}</Col>
                        </FormGroup>

                        <FormGroup row>
                          <Label sm={2}>내용</Label>
                          <Col sm={10}><div dangerouslySetInnerHTML={{ __html: state.question?.content }}></div></Col>
                        </FormGroup>

                        <FormGroup row>
                          <Label sm={2}>첨부파일</Label>
                          <Col sm={10}></Col>
                        </FormGroup>

                        <Col xs={12}>
                          <hr />
                          <b>답변 등록</b>
                        </Col>

                        <FormGroup row>
                          <Label sm={2}>*답변</Label>
                          <Col sm={10}>
                            <Input
                              name="answer"
                              type="textarea"
                              placeholder="답변을 입력해주세요"
                              onChange={formOption.answer.onChange}
                              onBlur={formOption.answer.onBlur}
                              innerRef={formOption.answer.ref}
                              invalid={!!errors.answer}
                            />
                          </Col>
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <div className="d-flex gap-2 justify-content-center">
                          <Button
                            color="secondary"
                            onClick={() => {
                              navigate(-1)
                            }}
                          >
                            최소
                          </Button>
                          <Button type="submit" color="primary">
                            저장
                          </Button>
                        </div>
                      </Col>
                    </Row>

                    <Row className="mt-3">
                      <Col sm={2}>
                      </Col>
                      <Col sm={10}>

                        {
                          state.question?.answer &&
                          <div>
                            {state.question.adminUser.name} &nbsp;&nbsp;&nbsp; {state.question.answerAt} <br/><br/>
                            {state.question.answer}
                          </div>
                        }
                      </Col>                      
                    </Row>
                    <Row className="mt-3">
                      <Col xs={12} className="mt-3">
                        <div className="d-flex gap-2 justify-content-center">
                          <Button
                            color="secondary"
                            onClick={() => {
                              navigate(-1)
                            }}
                          >
                            확인
                          </Button>
                        </div>                          
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default QuestionReplyForm
